<template>
  <div class="arrlist-content">
    <div class="search-box">
      <el-form :inline="true" :model="searchFrom" ref="searchFrom" class="demo-form-inline" size="small">
        <el-form-item label-width="100px" :label="$t('order.ArrList.924216-0')" prop="paymentState">
          <el-select clearable v-model="searchFrom.paymentState" :placeholder="$t('order.ArrList.924216-1')"
            style="width: 240px">
            <el-option v-for="item in $dict.paymentState" :key="item.code" :label="item.text"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="100px" :label="$t('order.ArrList.924216-2')" prop="serialNumber">
          <el-input style="width: 240px" clearable v-model="searchFrom.serialNumber"
            :placeholder="$t('order.ArrList.924216-3')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pinAn.uniformTradeOrder.865777-101')">
          <el-input v-model="searchFrom.payId" :placeholder="$t('pinAn.uniformTradeOrder.865777-101')"></el-input>
        </el-form-item>

        <el-form-item label-width="100px" :label="$t('order.ArrList.924216-4')" prop="status">
          <el-select style="width: 240px" clearable v-model="searchFrom.status"
            :placeholder="$t('order.ArrList.924216-5')">
            <el-option v-for="item in $dict.orderStatus" :key="item.code" :label="item.text"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="100px" :label="$t('order.ArrList.924216-6')" prop="consigneeName">
          <el-input style="width: 240px" clearable v-model="searchFrom.consigneeName"
            :placeholder="$t('order.ArrList.924216-7')"></el-input>
        </el-form-item>
        <el-form-item label-width="100px" :label="$t('order.ArrList.924216-8')" prop="consigneePhone">
          <el-input style="width: 240px" clearable v-model="searchFrom.consigneePhone"
            :placeholder="$t('order.ArrList.924216-9')"></el-input>
        </el-form-item>
        <el-form-item label-width="100px" :label="$t('order.ArrList.924216-10')" prop="goodsName">
          <el-input style="width: 240px" clearable v-model="searchFrom.goodsName"
            :placeholder="$t('order.ArrList.924216-11')"></el-input>
        </el-form-item>
        <el-form-item label-width="100px" :label="$t('order.ArrList.924216-12')">
          <el-date-picker style="width: 240px" value-format="yyyy-MM-dd" @change="placeChange"
            v-model="placeReceiptTime" type="daterange" :range-separator="$t('order.ArrList.924216-13')"
            :start-placeholder="$t('order.ArrList.924216-14')" :end-placeholder="$t('order.ArrList.924216-15')">
          </el-date-picker>
        </el-form-item>

        <el-form-item label-width="100px" :label="$t('order.ArrList.924216-16')">
          <el-date-picker style="width: 240px" value-format="yyyy-MM-dd" @change="pickerChange"
            v-model="confirmReceiptTime" type="daterange" :range-separator="$t('order.ArrList.924216-13')"
            :start-placeholder="$t('order.ArrList.924216-14')" :end-placeholder="$t('order.ArrList.924216-15')">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="100px" :label="$t('order.ArrList.924216-17')">
          <el-date-picker style="width: 240px" value-format="yyyy-MM-dd" @change="confirmTimeChange"
            v-model="confirmTime" type="daterange" :range-separator="$t('order.ArrList.924216-13')"
            :start-placeholder="$t('order.ArrList.924216-14')" :end-placeholder="$t('order.ArrList.924216-15')">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="100px" :label="$t('order.ArrList.924216-18')" prop="wlStatus">
          <el-select style="width: 240px" clearable v-model="searchFrom.wlStatus"
            :placeholder="$t('order.ArrList.924216-19')">
            <el-option :label="$t('order.ArrList.924216-20')" value="1"></el-option>
            <el-option :label="$t('order.ArrList.924216-21')" value="0"></el-option>
          </el-select>
        </el-form-item>
        <!-- {{ $t('order.ArrList.924216-18') }} -->
        <el-form-item :label="$t('order.ArrList.924216-22')" label-width="100px">
          <el-select style="width: 220px" clearable v-model="searchFrom.isPubGoods"
            :placeholder="$t('order.ArrList.924216-23')">
            <el-option :label="$t('order.ArrList.924216-24')" value="1"></el-option>
            <el-option :label="$t('order.ArrList.924216-25')" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" style="margin-left: 100px" @click="search">{{
            $t("order.ArrList.924216-26") }}</el-button>
          <el-button @click="resetForm">{{
            $t("order.ArrList.924216-27")
          }}</el-button>
          <el-button @click="exportEXCEL">{{
            $t("order.ArrList.924216-28")
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="content-table">
      <div class="content-table customer-table">
        <el-table :data="tableData" style="width: 100%" :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }" border>
          <el-table-column type="index" label="#"> </el-table-column>
          <el-table-column prop="serialNumber" :label="$t('order.ArrList.924216-2')" width="200">
          </el-table-column>
          <el-table-column prop="payId" header-align="center" align="center" width="200"
            :label="$t('pinAn.uniformTradeOrder.865777-101')"></el-table-column>
          <el-table-column prop="userId" :label="$t('order.ArrList.924216-29')" width="auto">
          </el-table-column>
          <el-table-column :label="$t('order.ArrList.924216-10')" width="170">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.orderDetailList" :key="index" class="slb-cell" :class="index === scope.row.orderDetailList.length - 1
                ? 'border-none'
                : ''
                ">
                <span>{{ item.goodsName }}</span>
              </div>
              <span> </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('order.ArrList.924216-30')" width="120">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.orderDetailList" :key="index" class="slb-cell" :class="index === scope.row.orderDetailList.length - 1
                ? 'border-none'
                : ''
                ">
                <span>{{ item.quantity }}</span>
              </div>
              <span> </span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('order.ArrList.924216-31')" min-width="120">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.orderDetailList" :key="index" class="slb-cell" :class="index === scope.row.orderDetailList.length - 1
                ? 'border-none'
                : ''
                ">
                <span>$HK{{ item.price }}</span>
              </div>
              <span> </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('order.ArrList.924216-32')" min-width="120">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.orderDetailList" :key="index" class="slb-cell" :class="index === scope.row.orderDetailList.length - 1
                ? 'border-none'
                : ''
                ">
                <span>$HK{{ item.amount }}</span>
              </div>
              <span> </span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('order.ArrList.924216-18')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.wlStatus == 0">{{
                $t("order.ArrList.924216-21")
              }}</span>
              <span v-else>{{ $t("order.ArrList.924216-20") }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('order.ArrList.924216-22')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.isPubGoods == 0">{{
                $t("order.ArrList.924216-25")
              }}</span>
              <span v-else>{{ $t("order.ArrList.924216-24") }}</span>
            </template>
          </el-table-column>
          <!--  -->
          <el-table-column prop="consigneeName" :label="$t('order.ArrList.924216-6')" min-width="120">
          </el-table-column>
          <el-table-column prop="consigneePhone" :label="$t('order.ArrList.924216-33')" min-width="120">
          </el-table-column>
          <el-table-column prop="address" :label="$t('order.ArrList.924216-34')" min-width="180">
            <template slot-scope="scope">
              <span :class="{ isUpdate: scope.row.isModifyAddress == 1 }">
                {{ scope.row.address }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="payTime" :label="$t('order.ArrList.924216-12')" min-width="140">
            <template slot-scope="scope">
              <span v-if="scope.row.payTime">{{ scope.row.payTime }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="total" :label="$t('order.ArrList.924216-35')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.total">$HK{{ scope.row.total }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="total" :label="$t('order.ArrList.924216-79')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.couponsAmount">$HK{{ scope.row.couponsAmount }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="totalGwj" :label="$t('order.ArrList.924216-36')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.totalGwj">$HK{{ scope.row.totalGwj }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="totalGzGwj" :label="$t('order.ArrList.924216-37')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.totalGzGwj">$HK{{ scope.row.totalGzGwj }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="totalAmount" :label="$t('order.ArrList.924216-38')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.totalAmount">$HK{{ scope.row.totalAmount }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!--  -->
          <el-table-column prop="commission" :label="$t('order.ArrList.924216-39')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.commission">$HK{{ scope.row.commission }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="profit" :label="$t('order.ArrList.924216-40')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.profit">$HK{{ scope.row.profit }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="paymentState" :label="$t('order.ArrList.924216-0')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.orderState === 3">{{
                $t("order.ArrList.924216-41")
              }}</span>
              <span v-else-if="scope.row.orderState === 102">{{
                $t("order.ArrList.924216-42")
              }}</span>

              <span v-else>{{
                $dict.paymentState | dictFormat(scope.row.paymentState)
              }}</span>
            </template>
            <!-- dictFormat -->
          </el-table-column>
          <el-table-column prop="paymentChannel" :label="$t('order.ArrList.924216-43')" min-width="120">
            <template slot-scope="scope">
              <span>{{
                $dict.paymentType | dictFormat(scope.row.paymentChannel)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="buyerMessage" :label="$t('order.ArrList.924216-44')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.buyerMessage">{{
                scope.row.buyerMessage
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="shippingState" :label="$t('order.ArrList.924216-4')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.shippingState === 4 && scope.row.wlStatus == 1">
                {{ $t("order.ArrList.924216-45") }}
              </span>
              <span v-else>{{
                $dict.orderStatus | dictFormat(scope.row.shippingState)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="confirmReceiptTime" :label="$t('order.ArrList.924216-16')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.confirmReceiptTime">{{
                scope.row.confirmReceiptTime
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="shippingTime" :label="$t('order.ArrList.924216-17')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.shippingTime">{{
                scope.row.shippingTime
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="cashSettleTime" :label="$t('order.ArrList.924216-46')" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.cashSettleTime">{{
                scope.row.cashSettleTime
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="gwjSettleTime" :label="$t('order.ArrList.924216-47')" min-width="120">
            <template slot-scope="scope">
              <span v-if="
                scope.row.gwjSettleTime &&
                (scope.row.totalGwj || scope.row.totalGzGwj)
              ">{{ scope.row.gwjSettleTime }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('order.ArrList.924216-48')" width="180" fixed="right">
            <template slot-scope="scope">
              <span v-if="scope.row.shippingState === 4 && scope.row.wlStatus == 1">{{ $t("order.ArrList.924216-49")
                }}</span>
              <template v-else>
                <!-- currentRow -->
                <el-button type="text" size="small" @click="toOrderDetails(scope.row.orderId, scope.row)">{{
                  $t("order.ArrList.924216-50") }}
                </el-button>
                <el-button v-if="scope.row.shippingState >= 4" type="text" size="small"
                  @click="toLogisticsInfoOpen(scope.row)">
                  {{ $t("order.ArrList.924216-51") }}
                </el-button>
                <el-button v-if="scope.row.shippingState === 3" type="text" size="small" @click="deliver(scope.row)">{{
                  $t("order.ArrList.924216-52") }}</el-button>
                <el-button v-if="scope.row.shippingState === 3" type="text" size="small"
                  @click="editReceiver(scope.row)">{{ $t("order.ArrList.924216-53") }}</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="pagination-box">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
        :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

    <el-dialog :title="$t('order.ArrList.924216-52')" :visible.sync="deliverDialogVisible" width="30%"
      @close="deliverDialogClose" v-if="deliverDialogVisible">
      <div>
        <el-form ref="deliverForm" :model="deliverForm" :rules="rules">
          <el-form-item>
            <p class="noticeTxt">
              {{ $t("order.ArrList.924216-54")
              }}{{ $t("order.ArrList.924216-55") }}
            </p>
          </el-form-item>
          <el-form-item :label="$t('order.ArrList.924216-56')" prop="emsId" label-width="120px">
            <el-select v-model="deliverForm.emsId" :placeholder="$t('order.ArrList.924216-57')" style="width: 280px"
              filterable allow-create>
              <el-option v-for="item in logisticsList" :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
            <el-button @click="addDomain" type="primary" class="maginBtn">{{
              $t("order.ArrList.924216-55")
            }}</el-button>
          </el-form-item>

          <el-form-item label-width="120px" v-for="(domain, index) in deliverForm.domains"
            :label="$t('order.ArrList.924216-58') + (index + 1)" :key="domain.key" :prop="'domains.' + index + '.value'"
            :rules="{
              required: true,
              message: $t('order.ArrList.924216-59'),
              trigger: 'blur',
            }">
            <el-input v-model="domain.value" style="width: 60%"></el-input><el-button class="maginBtn"
              @click.prevent="removeDomain(domain)">{{ $t("order.ArrList.924216-60") }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deliverDialogClose">{{
          $t("order.ArrList.924216-61")
        }}</el-button>
        <el-button type="primary" @click="deliverSubmit">{{
          $t("order.ArrList.924216-62")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- {{ $t('order.ArrList.924216-63') }} -->
    <el-dialog :title="$t('order.ArrList.924216-63')" :visible.sync="logisticsReviseDialogVisible" width="30%">
      <div>
        <el-form ref="logisticsReviseForm" :model="logisticsReviseForm" :rules="rules"
          :before-close="logisticsReviseDialogClose">
          <el-form-item :label="$t('order.ArrList.924216-56')" prop="emsId" label-width="120px">
            <el-select v-model="logisticsReviseForm.emsId" :placeholder="$t('order.ArrList.924216-57')"
              style="width: 280px">
              <el-option v-for="item in logisticsList" :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('order.ArrList.924216-58')" prop="emsNum" label-width="120px">
            <el-input type="text" style="width: 280px" onKeyUp="value=value.replace(/[\W]/g,'')"
              :placeholder="$t('order.ArrList.924216-64')" v-model="logisticsReviseForm.emsNum" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logisticsReviseDialogVisible = false">{{
          $t("order.ArrList.924216-61")
        }}</el-button>
        <el-button type="primary" @click="logisticsReviseSubmit">{{
          $t("order.ArrList.924216-62")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 侧边栏查看详情 -->
    <el-drawer :title="$t('order.ArrList.924216-65')" size="60%" :visible.sync="detailsDrawerVisible">
      <Details :orderData.sync="orderDetailData" :isSharedGoods.sync="isSharedGoods" @logisticsRevise="logisticsRevise"
        @logisticsOpen="logisticsOpen"></Details>
    </el-drawer>
    <!-- 物流详情 -->
    <logisticsInfo ref="logistics" :show="logisticsInfoShow" @close="logisticsInfoClose"
      @logisticsRevise="logisticsRevise" />

    <footer>
      <el-dialog :visible.sync="showRecieveInfo" :title="$t('order.ArrList.924216-66')" width="30%"
        @close="logisticsClose" v-if="showRecieveInfo">
        <el-form :model="recieveForm" :inline="true" label-width="130px" label-position="left">
          <el-form-item :label="$t('order.ArrList.924216-67')">
            <el-input v-model="recieveForm.consigneeName" class="inp">
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('order.ArrList.924216-68')">
            <el-input v-model="recieveForm.consigneePhone" class="inp" />
          </el-form-item>

          <el-form-item :label="$t('order.ArrList.924216-69')" label-width="110px">
            <div class="search_address">
              <el-select class="areaSelect" v-model="recieveForm.province" :placeholder="$t('order.ArrList.924216-70')"
                style="margin-right: 10px" @change="provinceChange" clearable>
                <el-option v-for="item in optionsProvince" :key="item.value" :label="item.text" :value="item.value" />
              </el-select>
              <el-select class="areaSelect" v-model="recieveForm.city" :placeholder="$t('order.ArrList.924216-71')"
                style="margin-right: 10px" @change="cityChange" :disabled="!recieveForm.province" clearable>
                <el-option v-for="item in optionsCity" :key="item.value" :label="item.text" :value="item.value" />
              </el-select>
              <el-select class="areaSelect" v-model="recieveForm.county" :placeholder="$t('order.ArrList.924216-72')"
                style="margin-right: 10px" @change="areaChange" :disabled="!recieveForm.city" clearable>
                <el-option v-for="item in optionsArea" :key="item.value" :label="item.text" :value="item.value" />
              </el-select>
            </div>
          </el-form-item>
          <el-form-item :label="$t('order.ArrList.924216-73')">
            <el-input v-model="recieveForm.detailed" class="inp" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="logisticsClose">{{
              $t("order.ArrList.924216-74")
            }}</el-button>
            <el-button type="primary" @click="sumitEditlogistics">{{
              $t("order.ArrList.924216-75")
            }}</el-button>
          </span>
        </template>
      </el-dialog>
    </footer>
  </div>
</template>
<script>
// 查看详情
import Details from "@/views/manage/order/Details.vue";
import logisticsInfo from "@/views/manage/order/logisticsInfo.vue";
import { getCity, getProvince, getArea } from "@/utils/addressList";

export default {
  data() {
    return {
      showRecieveInfo: false,
      recieveForm: {
        consigneeName: null,
        consigneePhone: null,
        province: null,
        city: null,
        county: null,
        detailed: null,
        orderId: null,
      },
      optionsProvince: getProvince(),
      optionsCity: [],
      optionsArea: [],
      provinceName: null,
      cityName: null,
      areaName: null,
      /* ---------- */
      logisticsReviseDialogVisible: false, //修改物流信息显示隐藏
      logisticsInfoShow: false, //物流详情显示隐藏
      detailsDrawerVisible: false, //详情显示隐藏
      currentRow: [], //当前操作row

      placeReceiptTime: null, //临时储存下单时间
      confirmReceiptTime: null, //临时储存确认收货时间
      confirmTime: null, //确认收货时间
      searchFrom: {
        // confirmReceiptTime: "", //确认收货时间
        payId: "",
        isPubGoods: "", //订单类别 1.共享 0.普通
        wlStatus: null,
        serialNumber: "", //订单编号
        consigneeName: "", //收货人姓名
        consigneePhone: "", //收货人电话
        paymentState: "", //支付状态
        payBeginTime: "", //下单开始时间
        payEndTime: "", //下单结束时间
        goodsName: "", //商品名称
        status: "", //订单状态
        confirmBeginTime: "", //确认收货开始时间
        confirmEndTime: "", //确认收货结束时间
        shippingTimeStart: "", //发货收货开始时间
        shippingTimeEnd: "", //发货结束时间
        paymentChannel: "",
      },
      logisticsList: [], //物流列表
      // 发货表单
      deliverForm: {
        emsId: null,
        emsNum: null,
        domains: [],
      },
      deliverDialogVisible: false, //发货弹窗
      // 校验规则
      rules: {
        emsId: [
          {
            required: true,
            message: this.$t("order.ArrList.924216-76"),
            trigger: "change",
          },
        ],
        // domains: [
        //   {
        //     required: true,
        //     message: this.$t('order.ArrList.924216-64'),
        //     trigger: "blur",
        //   },
        // ],
      },
      // 修改物流信息表单
      logisticsReviseForm: {
        emsId: null,
        emsNum: null,
      },
      // 表格数据
      tableData: [],
      // 条数
      pageSize: 10,
      // 当前页
      pageNum: 1,
      // 总条数
      total: 0,
      currentOrderId: null, //选中订单ID
      orderDetailData: null,
      linkUrl: "https://www.kuaidi100.com/?from=openv",
      isSharedGoods: null,
    };
  },
  components: {
    Details,
    logisticsInfo,
  },
  created() {
    this.getOrderList();
    this.getLogisticsList();
  },
  methods: {
    // 初始化init  // 获取订单列表
    getOrderList() {
      let resData = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        ...this.searchFrom,
      };
      // console.log(resData);
      this.loading = true;
      this.$api.order
        .shopOrderList(this.$formatSubForm(resData))
        .then((res) => {
          this.loading = false;
          if (!res.success) {
            this.tableData = [];
            this.total = 0;
            return;
          }
          this.tableData = res.data.list;
          // console.log(res.data.list);
          this.total = parseInt(res.data.total);
        });
    },

    logisticsReviseDialogClose() {
      // 修改物流信息关闭
      this.logisticsReviseDialogVisible = false;
    },

    // 修改物流信息提交
    logisticsReviseSubmit() {
      this.$api.order
        .updateOrderEms({
          emsId: this.logisticsReviseForm.emsId,
          emsNum: this.logisticsReviseForm.emsNum,
          orderDetailId: this.currentRow.orderId,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.logisticsReviseDialogVisible = false;
            this.logisticsInfoShow = false;
            this.detailsDrawerVisible = false;
          } else {
            this.$message.error(res.message);
          }
          this.getOrderList();
          this.getLogisticsList();
        });
    },

    //-----------=== 查看物流详情
    toLogisticsInfoOpen(row) {
      //复制链接
      let item = row.shipNumber;
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = item;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand("Copy");
      this.$message({
        type: "success",
        message: this.$t("order.ArrList.924216-77"),
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
      // window.location.href = this.linkUrl;
      window.open(this.linkUrl, "_blank");
    },

    //-----------=== 子组件查看物流详情
    logisticsOpen() {
      this.$refs.logistics.queryWLInfo(this.currentRow.shipNumber);
    },

    //-----------=== 子组件修改物流
    logisticsRevise() {
      this.logisticsReviseForm.emsId = parseInt(this.currentRow.emsId);
      this.logisticsReviseForm.emsNum = this.currentRow.shipNumber;
      this.logisticsReviseDialogVisible = true;
    },

    //-----------=== 物流信息关闭
    logisticsInfoClose() {
      this.logisticsInfoShow = false;
    },

    //-----------=== 下单时间
    placeChange(value) {
      if (!value) {
        [this.searchFrom.payBeginTime, this.searchFrom.payEndTime] = [
          null,
          null,
        ];
        return;
      }
      this.searchFrom.payBeginTime = value[0];
      this.searchFrom.payEndTime = value[1];
    },

    //-----------=== 收货时间 confirmReceiptTime
    pickerChange(value) {
      if (!value) {
        [this.searchFrom.confirmBeginTime, this.searchFrom.confirmEndTime] = [
          null,
          null,
        ];
        return;
      }
      this.searchFrom.confirmBeginTime = value[0];
      this.searchFrom.confirmEndTime = value[1];
    },

    /**
     * @description:  发货时间 change
     * @Date: 2022-10-27 14:45:41
     * @author: cc
     */
    confirmTimeChange(value) {
      if (!value) {
        [this.searchFrom.shippingTimeStart, this.searchFrom.shippingTimeEnd] = [
          null,
          null,
        ];
        return;
      }
      [this.searchFrom.shippingTimeStart, this.searchFrom.shippingTimeEnd] =
        value;
    },

    // 导出EXcel
    exportEXCEL() {
      this.$api.file
        .exportEXCEL(this.searchFrom, "/api/backstage/doExportOrderExcel")
        .then((res) => {
          // console.log(res);

          let reader = new FileReader();
          reader.readAsDataURL(res); //转换为base64，可以直接放入a标签href   接受的数据一定要配置responseType: blob
          reader.onload = function (e) {
            let a = document.createElement("a");
            a.download = ""; //此处填写文件地址
            a.href = e.target.result;
            document.body.appendChild(a); // 修复firefox中无法触发click
            a.click();
            document.body.removeChild(a);
          };
        });
    },

    // 获取物流公司列表
    getLogisticsList() {
      this.$api.order.slbemsEmsList().then((res) => {
        // console.log(res);
        if (res.success) {
          this.logisticsList = res.data ? res.data : [];
        }
      });
    },

    /**
     * 编辑收件人信息
     * @Date: 2022-10-06 09:12:52
     * @author: cc
     */

    editReceiver(row) {
      this.$api.order
        .orderDetails({
          id: row.orderId,
          isSharedGoods: row.isPubGoods,
        })
        .then((res) => {
          if (!res.data) {
            this.$message.warning(res.message);
            return;
          }
          // console.log(res);
          // 收件信息编辑-数据渲染
          this.recieveForm.consigneeName = res.data.consigneeName;
          this.recieveForm.consigneePhone = res.data.consigneePhone;
          this.recieveForm.province = res.data.province;
          this.recieveForm.city = res.data.city;
          this.recieveForm.county = res.data.county;
          this.recieveForm.detailed = res.data.detailed;
          this.recieveForm.orderId = row.orderId;

          this.provinceName = res.data.province;
          this.cityName = res.data.city;
          this.areaName = res.data.county;
          //  显示物流详情弹层
          this.showRecieveInfo = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /* 收件地址编辑弹层关闭 */
    logisticsClose() {
      this.recieveForm = {};
      this.showRecieveInfo = false;
    },

    /* 修改收件信息 */
    sumitEditlogistics() {
      this.$api.order
        .updateReceiveAddress({
          ...this.recieveForm,
          province: this.provinceName,
          city: this.cityName,
          county: this.areaName,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.showRecieveInfo = false;
          this.getOrderList();
        })
        .catch((err) => {
          this.showRecieveInfo = false;
        });
    },

    addDomain() {
      this.deliverForm.domains.push({
        value: "",
        key: Date.now(),
      });
    },

    removeDomain(item) {
      var index = this.deliverForm.domains.indexOf(item);
      if (index !== -1) {
        this.deliverForm.domains.splice(index, 1);
      }
    },

    provinceChange(val) {
      this.recieveForm.city = "";
      this.optionsCity = getCity(val);
      let keywords = "";

      for (let i of this.optionsProvince) {
        if (val == i.value) {
          keywords = i.text;
        }
      }
      this.provinceName = keywords;
    },

    cityChange(val) {
      this.recieveForm.county = "";
      this.optionsArea = getArea(val);
      let keywords = "";
      for (let i of this.optionsCity) {
        if (val == i.value) {
          keywords = i.text;
        }
      }
      this.cityName = keywords;
    },

    areaChange(val) {
      let keywords = "";
      for (let i of this.optionsArea) {
        if (val == i.value) {
          keywords = i.text;
        }
      }
      this.areaName = keywords;
    },

    // 查看
    toOrderDetails(id, row) {
      // 保存当前操作列表对应行
      this.currentRow = row;
      const isSharedGoods = row.isPubGoods;
      this.isSharedGoods = row.isPubGoods;
      // console.log(id);
      // 查看 详情
      this.$api.order
        .orderDetails({
          id: id,
          isSharedGoods: isSharedGoods,
        })
        .then((res) => {
          const { data, success, message } = res;
          // data
          if (!success) {
            this.$message.error(message);
            return;
          }
          this.orderDetailData = data;
          // console.log(res);
          this.detailsDrawerVisible = true;
        });
    },

    // 搜索重置
    resetForm() {
      // this.searchFrom.payBeginTime = null;
      // this.searchFrom.payEndTime = null;
      // this.searchFrom.confirmBeginTime = null;
      // this.searchFrom.confirmEndTime = null;
      // this.searchFrom.isPubGoods = null;
      this.$clearForm(this.searchFrom);
      this.confirmTime = null;
      this.placeReceiptTime = null;
      this.confirmReceiptTime = null;
      this.$refs["searchFrom"].resetFields();
      this.getOrderList();
    },

    // 搜索
    search() {
      this.getOrderList();
    },

    // 弹窗显示 发货
    deliver(item) {
      this.currentOrderId = item.orderId;
      this.deliverDialogVisible = true;
    },

    // 发货提交
    deliverSubmit() {
      this.$refs["deliverForm"].validate(async (valid) => {
        if (valid) {
          let emsNumArrat = [];
          this.deliverForm.domains.forEach((item) => {
            emsNumArrat.push(item.value);
          });
          let resData = {
            emsId: this.deliverForm.emsId,
            emsNum: emsNumArrat.toString(),
            orderId: this.currentOrderId,
          };
          if (!resData.emsNum) {
            this.$message({
              type: "info",
              message: this.$t("order.ArrList.924216-78"),
            });
            return;
          }
          await this.$api.order.confirmShopOrder(resData).then((res) => {
            if (res.success) {
              this.getOrderList();

              this.$message({
                type: "success",
                message: res.message,
              });
            } else {
              this.$message.error(res.message);
            }
            this.deliverDialogClose();
          });
        }
      });
    },

    // 发货弹窗关闭
    deliverDialogClose() {
      // this.$refs["deliverForm"].resetFields();
      Object.keys(this.deliverForm).forEach((item) => {
        this.deliverForm[item] = null;
      });
      this.deliverForm.domains = [];
      this.deliverDialogVisible = false;
    },

    handleSizeChange(val) {
      // 每页条数
      this.pageSize = val;
      this.getOrderList();
    },
    handleCurrentChange(val) {
      // 当前页面
      this.pageNum = val;
      this.getOrderList();
    },

    test(val) {
      console.log(val, "test");
    },
  },
};
</script>

<style lang="scss" scoped>
.isUpdate {
  color: red;
}

.arrlist-content {
  .pagination-box {
    padding: 8px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.maginBtn {
  margin-left: 20px;
}

.noticeTxt {
  color: #ff5000;
}

.slb-cell {
  padding: 0 8px;
  height: 50px;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #dddddd;

  &.border-none {
    border: none;
  }
}

.inp {
  width: 100%;
}
</style>
<style lang="scss">
.arrlist-content {
  footer {
    .el-form-item .el-form-item__content {
      width: 100%;
    }

    .el-form-item--small.el-form-item {
      display: flex;
      justify-content: space-between;
    }
  }

  .search_address .el-select {
    width: 25%;
  }

  .search_address {
    margin-left: 3%;
  }
}
</style>
